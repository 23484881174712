import request from "@/utils/request.js"

export default {
  // 获取产品
  getProduct(data) {
    return request({
      url: "/product/getProduct",
      method: "post",
      data: data,
    })
  },
  // 添加产品
  addProduct(data) {
    return request({
      url: "/product/addProduct",
      method: "post",
      data: data,
    })
  },
  // 更新产品信息
  updateProduct(data) {
    data.address = JSON.parse(localStorage.getItem("userInfo")).address
    data.company = JSON.parse(localStorage.getItem("userInfo")).company
    return request({
      url: "/product/updateProduct",
      method: "post",
      data: data,
    })
  },
  // 获取产品分类
  getCate() {
    return request({
      url: "/product/getCate?companyId=" + JSON.parse(localStorage.getItem("userInfo")).companyId,
      method: "get",
    })
  },
  // 添加产品分类
  addCate(obj) {
    return request({
      url: "/product/addCate",
      method: "post",
      data: obj,
    })
  },
  // 修改产品分类
  updateCate(obj) {
    return request({
      url: "/product/updateCate",
      method: "post",
      data: obj,
    })
  },
  // 删除产品分类
  deleteCate(obj) {
    return request({
      url: "/product/deleteCate",
      method: "post",
      data: obj,
    })
  },
  // 获取产品参数
  getParam() {
    return request({
      url: "/product/getParam?companyId=" + JSON.parse(localStorage.getItem("userInfo")).companyId,
      method: "get",
    })
  },
  // 添加产品参数
  addParam(obj) {
    return request({
      url: "/product/addParam",
      method: "post",
      data: obj,
    })
  },
  // 修改产品参数
  updateParam(obj) {
    return request({
      url: "/product/updateParam",
      method: "post",
      data: obj,
    })
  },
  // 删除产品参数
  deleteParam(obj) {
    return request({
      url: "/product/deleteParam",
      method: "post",
      data: obj,
    })
  },
}
