<template>
  <div class="productAdd">
    <ProductEdit ref="productEdit" :picNo="picNo">
      <div class="flex" slot="form_submit">
        <el-button type="primary" @click="addProduct" :loading="saveLoad">新增产品</el-button>
      </div>
      <div class="flex" slot="ditor_submit">
        <el-button type="primary" @click="saveDitor" :loading="saveDitorLoad">保存详情</el-button>
      </div>
      <div class="flex" slot="seo_submit">
        <el-button type="primary" @click="saveSeo" :loading="saveSeoLoad">保存TDK</el-button>
      </div>
    </ProductEdit>
  </div>
</template>

<script>
import ProductEdit from "./modules/ProductEdit.vue"
import api from "@/api/product.js"
import utils from "@/utils/utils.js"
export default {
  components: {
    ProductEdit,
  },
  data() {
    // 获取随机生成的图片编号
    let picNo = utils.UUID(JSON.parse(localStorage.getItem("userInfo")).companyId + "_")
    return {
      picNo: picNo,
      productNo: "",
      // 按钮状态
      saveLoad: false,
      saveDitorLoad: false,
      saveSeoLoad: false,
    }
  },
  methods: {
    // 新增产品
    async addProduct() {
      // 获取产品信息
      let ruleForm = this.$refs.productEdit.ruleForm
      let paramList = this.$refs.productEdit.paramList
      let cateList = this.$refs.productEdit.cateList
      if (!ruleForm.name) {
        this.$message({
          type: "error",
          message: "请填写产品名称！",
          duration: 800,
        })
        return
      }
      if (!ruleForm.pic) {
        this.$message({
          type: "error",
          message: "请上传缩略图！",
          duration: 800,
        })
        return
      }
      if (cateList != [] && !ruleForm.cateNo) {
        this.$message({
          type: "error",
          message: "请选择分类！",
          duration: 800,
        })
        return
      }
      this.saveLoad = true
      let params = []
      paramList.forEach((item) => {
        params.push({
          paramName: item.paramName,
          paramNo: item.paramNo,
          content: item.content,
        })
      })
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        name: ruleForm.name,
        pic: ruleForm.pic,
        cateNo: ruleForm.cateNo,
        cateName: ruleForm.cateName,
        price: ruleForm.price,
        oldPrice: ruleForm.oldPrice,
        paramList: JSON.stringify(params),
        vrUrl: ruleForm.vrUrl,
        isShow: false,
        isTop: false,
        isHide: true,
      }
      try {
        const res = await api.addProduct(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
            duration: 1000,
          })
          this.productNo = res.data.result.productNo
        } else {
          this.$message.error("网络故障！")
        }
        this.saveLoad = false
      } catch (error) {
        this.$message.error(error)
        this.saveLoad = false
      }
    },
    // 保存产品详情
    async saveDitor() {
      this.saveDitorLoad = true
      // 获取产品信息
      let ruleForm = this.$refs.productEdit.ruleForm
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        productNo: this.productNo,
        ditorHtml: JSON.stringify(ruleForm.ditorHtml),
      }
      try {
        const res = await api.updateProduct(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          })
        } else {
          this.$message.error("网络故障！")
        }
        this.saveDitorLoad = false
      } catch (error) {
        this.$message.error(error)
        this.saveDitorLoad = false
      }
    },
    // 保存产品seo信息
    async saveSeo() {
      this.saveSeoLoad = true
      // 获取产品信息
      let ruleForm = this.$refs.productEdit.ruleForm
      let condition = {
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        productNo: this.productNo,
        seoTitle: ruleForm.seoTitle,
        seoKeyword: ruleForm.seoKeyword,
        seoDesc: ruleForm.seoDesc,
      }
      try {
        const res = await api.updateProduct(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 1000,
          })
        } else {
          this.$message.error("网络故障！")
        }
        this.saveSeoLoad = false
      } catch (error) {
        this.$message.error(error)
        this.saveSeoLoad = false
      }
    },
  },
}
</script>

<style></style>
