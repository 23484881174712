<template>
  <div class="productEdit">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="3rem">
          <el-form-item label="产品名称" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-form-item label="产品图片" prop="pic">
            <Upload
              text="必须上传"
              :pic="ruleForm.pic"
              :picName="picNo"
              path="product"
              @uploadSuccess="uploadSuccess"
              @deleteSuccess="deleteSuccess"
            />
          </el-form-item>
          <el-form-item label="产品分类" prop="cateNo" :required="cateList.length > 0">
            <el-select v-model="ruleForm.cateNo" placeholder="请选择产品分类">
              <el-option
                v-for="item of cateList"
                :key="item.cateNo"
                :label="item.cateName"
                :value="item.cateNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="VR链接" prop="vrUrl">
            <el-input v-model="ruleForm.vrUrl" />
          </el-form-item>
          <el-form-item label="产品价格" prop="price">
            <el-input v-model="ruleForm.price" />
          </el-form-item>
          <el-form-item label="市场价格" prop="oldPrice">
            <el-input v-model="ruleForm.oldPrice" />
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <div class="productEdit_param-title flex">
              <p>产品参数</p>
              <el-button type="primary" @click="dialogVisible = true">管理产品参数</el-button>
            </div>
          </el-form-item>
          <div class="productEdit_param-content flex">
            <el-form-item v-for="item in paramList" :key="item.paramNo" :label="item.paramName">
              <el-input v-model="item.content" />
            </el-form-item>
          </div>
          <el-divider></el-divider>
          <slot name="form_submit"></slot>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="详情介绍" name="second">
        <Editor :ditorHtml="ruleForm.ditorHtml" imgType="product" @updateHtml="updateHtml" />
        <slot name="ditor_submit"></slot>
      </el-tab-pane>
      <el-tab-pane label="TDK管理" name="third">
        <el-form class="productEdit_tdk" label-width="3rem">
          <el-form-item label="SEO标题"> <el-input v-model="ruleForm.seoTitle" /> </el-form-item
          ><el-form-item label="SEO关键词">
            <el-input v-model="ruleForm.seoKeyword" placeholder="注：多个关键词用英文“,”隔开" />
          </el-form-item>
          <el-form-item label="SEO描述">
            <el-input
              v-model="ruleForm.seoDesc"
              type="textarea"
              :rows="2"
              style="width: 12rem"
            /> </el-form-item
        ></el-form>
        <slot name="seo_submit"></slot>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="管理产品参数" append-to-body :visible.sync="dialogVisible" center>
      <ProductParam @updateParam="updateParam" />
    </el-dialog>
  </div>
</template>

<script>
import Upload from "@/components/Upload.vue"
import Editor from "@/components/Editor.vue"
import ProductParam from "../ProductParam.vue"
export default {
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {
          name: "",
          pic: "",
          cateNo: "",
          vrUrl: "",
          ditorHtml: "",
          paramList: [],
          seoTitle: "",
          seoKeyword: "",
          seoDesc: "",
          price: "",
          oldPrice: "",
        }
      },
    },
    picNo: {
      type: String,
    },
  },
  data() {
    return {
      activeName: "first",
      rules: {
        name: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
      },
      // 分类列表
      cateList: [],
      paramList: [],
      dialogVisible: false,
    }
  },
  methods: {
    // 上传图片成功
    uploadSuccess(val) {
      val.url = val.url + "?" + Math.floor(Math.random() * 100) + 0
      this.ruleForm.pic = val.url
    },
    // 删除图片成功
    deleteSuccess() {
      this.ruleForm.pic = ""
    },
    // 更新参数列表
    updateParam() {
      let list = JSON.parse(JSON.stringify(this.$store.getters.paramList))
      list.forEach((item) => {
        this.ruleForm.paramList.forEach((item2) => {
          if (item.paramNo == item2.paramNo) {
            item.content = item2.content
          }
        })
      })
      this.paramList = list
    },
    // 更新editor内容
    updateHtml(val) {
      this.ruleForm.ditorHtml = val
    },
  },
  mounted() {
    this.cateList = this.$store.getters.cateList
    this.updateParam()
  },
  components: {
    Upload,
    Editor,
    ProductParam,
  },
}
</script>

<style lang="scss" scoped>
.productEdit {
  .el-form {
    margin-top: 20px;
    &-item {
      display: flex;

      .productEdit_param-title {
        align-items: center;

        > p {
          margin-right: 20px;
          font-weight: bold;
        }
      }
    }
    &-item /deep/ .el-form-item__content {
      margin: 0 !important;
    }
    .el-input {
      width: 300px;
    }

    .productEdit_param-content {
      flex-wrap: wrap;

      > div {
        width: 50%;
      }
    }
  }

  .productEdit_tdk {
    .el-input {
      width: 12rem;
    }
  }

  .el-dialog__body {
    display: flex;
    justify-content: center;
  }
}
</style>
