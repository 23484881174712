<template>
  <div class="productParam">
    <div class="productParam_th flex">
      <p class="th_name">参数名称</p>
      <p class="th_sort">排序</p>
      <p class="th_func">操作</p>
    </div>
    <div v-show="!paramList.length > 0" class="productParam_error">
      <p>暂无数据</p>
    </div>
    <Draggable :list="paramList">
      <div
        class="productParam_td flex"
        v-for="(item, index) in paramList"
        :key="index"
        slot="drag_list"
      >
        <p class="td_name">{{ item.paramName }}</p>
        <p class="td_sort">{{ item.sort }}</p>
        <p class="td_func">
          <el-button type="text" @click="updateParam(item, index)"
            >编辑</el-button
          >
          <el-button
            type="text"
            style="color: red"
            @click="deleteParam(item.paramNo)"
            >删除</el-button
          >
        </p>
      </div>
    </Draggable>
    <p class="productParam_hint">注：拖动可进行排序</p>
    <el-button type="primary" @click="addParam">+ 添加产品参数</el-button>
    <el-button type="success" @click="submitParam">保存参数</el-button>
  </div>
</template>

<script>
import Draggable from "@/components/Draggable.vue"
import api from "@/api/product.js"
export default {
  data() {
    return {
      paramList: [],
    }
  },
  methods: {
    // 获取参数列表
    async getParam() {
      try {
        const res = await api.getParam()
        if (res.data.code == 200) {
          this.paramList = res.data.result || []
          this.$store.commit("setParamList", res.data.result || [])
          this.$emit("updateParam")
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 添加参数
    addParam() {
      const that = this
      this.$prompt("请输入参数名称", "添加参数", {
        confirmButtonText: "添加",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          that.submitAddParam(value)
        })
        .catch(() => {})
    },
    async submitAddParam(val) {
      try {
        let condition = {
          companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
          paramName: val,
          sort: 0,
        }
        const res = await api.addParam(condition)
        if (res.data.code == 200) {
          // 重新获取参数列表
          this.getParam()
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 编辑参数
    updateParam(val, index) {
      const that = this
      this.$prompt("请输入参数名称", "修改参数", {
        inputValue: val.paramName,
        confirmButtonText: "修改",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          that.paramList[index].paramName = value
        })
        .catch(() => {})
    },
    // 删除参数
    deleteParam(val) {
      const that = this
      this.$confirm("确认删除该参数吗?", "删除参数", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.submitDeleteParam(val)
        })
        .catch(() => {})
    },
    async submitDeleteParam(val) {
      try {
        let condition = {
          companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
          paramNo: val,
        }
        const res = await api.deleteParam(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: res.data.message,
            duration: 800,
          })
          // 重新获取参数列表
          this.getParam()
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 保存参数
    async submitParam() {
      try {
        let list = this.paramList
        list.forEach((item, index) => {
          item.sort = index + 1
        })
        let condition = {
          companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
          paramList: list,
        }
        const res = await api.updateParam(condition)
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功！",
            duration: 800,
          })
          // 重新获取参数列表
          this.getParam()
        } else {
          this.$message.error("网络故障！")
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
  mounted() {
    this.paramList = this.$store.getters.paramList
  },
  components: {
    Draggable,
  },
}
</script>

<style lang="scss">
.productParam {
  text-align: left;
  &_th {
    height: 40px;
    width: 800px;
    background-color: #f5f5f5;
    align-items: center;
    padding-left: 40px;
    font-weight: bold;

    .th_name {
      width: 60%;
      text-align: left;
    }

    .th_sort,
    .th_func {
      width: 20%;
    }
  }

  &_td {
    height: 40px;
    width: 800px;
    line-height: 40px;
    padding-left: 40px;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;

    .td_name {
      width: 60%;
      text-align: left;
    }

    .td_sort,
    .td_func {
      width: 20%;
    }
  }

  &_error {
    height: 40px;
    width: 800px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #e8e8e8;
    color: #999999;
  }

  &_hint {
    margin: 20px 0;
    color: #999999;
    text-align: left;
  }
}
</style>
